.main-content {
  position: relative;

  // Navbar
  .navbar-top {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: transparent;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  // Container
  .container-fluid {
    @include media-breakpoint-up(md) {
      padding-left: (
        $main-content-padding-x + $grid-gutter-width / 2
      ) !important;
      padding-right: (
        $main-content-padding-x + $grid-gutter-width / 2
      ) !important;
      padding-top: 125px !important;
    }
  }
}

// Offsets the main content depending on the sidebar positioning

.navbar-vertical.navbar-expand {
  @each $breakpoint, $dimension in $grid-breakpoints {
    &-#{$breakpoint} {
      @include media-breakpoint-up(#{$breakpoint}) {
        // Left
        &.fixed-left + .main-content {
          margin-left: $navbar-vertical-width;
        } // Right
        &.fixed-right + .main-content {
          margin-right: $navbar-vertical-width;
        }
      }
    }
  }
}

.ilwrapper{
  width: 20%;
  min-width: 400px;
}

.powerleft {
  width: 50%;
  height: 200px;
  background-position: 90%;
  background-repeat: no-repeat;
  margin-top: 20px;
  margin-bottom: 20px;
  background-size: 50%;
}
.powerright {
  width: 50%;
  height: 200px;
  background-position: 10%;
  background-repeat: no-repeat;
  margin-top: 20px;
  margin-bottom: 20px;
  background-size: 50%;
}

.powercenter {
  width: 50%;
  height: 200px;
  background-position: 50%;
  background-repeat: no-repeat;
  margin-top: 20px;
  margin-bottom: 20px;
  background-size: 50%;
  margin: auto;
}

.list {
  display: block;
  margin-top: 10px;
}

.logohead {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  height: auto;
  padding-bottom: 50px;

  // Add top padding to main content when navbar shift to top of screen (for small screens)
  @include media-breakpoint-down(sm) {
    padding-top: 100px;
  }
}

.liquidether {
  margin-top: 50px;
  text-align: center;
}

.lpreturns {
  display: block;
  width: 50%;
  margin: 0 auto;
  min-width: 333px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.utility {
  display: block;
  width: 30%;
  margin: 0 auto;
  min-width: 333px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.defispartan {
  width: 20%;
  max-width: 500px;
  position: absolute;
  right: 4px;
  bottom: -30px;
}
.liquiddiagram {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  height: auto;
  margin-top: 60px;
}
.poolexplorer {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
  min-width: 250px;
  padding-bottom: 20px;
}
.lpcardheader {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  margin-bottom: 0;
  min-width: 125px;
}
.tablefooter {
  font-size: 0.75rem;
}
.data {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 10%;
  margin: 0 auto;
  min-width: 250px;
  text-align: center;
}

.footer {
  background: none !important;
}
.buttonrow {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 30px;
}

.zap {
  text-align: center;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

.poolselect {
  width: 75%;
  margin: 0 auto;
  margin-bottom: 30px;
}
.connectbutton {
  background: "#4883F6";
  width: 75%;
  margin: 0 auto;
}

.providebutton {
  width: 50%;
}

.ethinput {

  width: 30%;
  min-width: 200px;
  margin: 0 auto;
}

.ethinputbox {
  width: 200px;
}
.ui.fluid.dropdown{
  width: 30%;
  min-width: 200px;
  margin: 0 auto;
}

.labelimg {
  vertical-align: center;
  align-content: center;
}

.zapheader h1 {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
}

.zapheader {
  @include media-breakpoint-down(sm) {
    padding-top: 100px;
  }
}

.about {
  width: 75%;
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
}

.info {
  width: 75%;
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
}

.disclaimer {
  width: 75%;
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
}

.abouticon {
  display: inline-block;
  margin-bottom: 20px;
}

.infoicon {
  display: inline-block;
  margin-bottom: 20px;
  color: white;
}

.disclaimericon {
  display: inline-block;
  color: red;
  margin-bottom: 20px;
}

.aboutcontent {
  text-align: left;
}

.infocontent {
  text-align: left;
  color: white;
  h3 {
    color: #4c84f4;
  }
}

.disclaimercontent {
  text-align: left;
}

.navbar-vertical {
  z-index: 100;
}

.navbar-vertical .navbar-nav .nav-link > i {
  margin-bottom: 10px;
}

.spinny {
  text-align: center;
  margin-top: 5em;
}

.newspinny {
  text-align: center;
}

.wallet {
  display: inline;
  margin-right: 20px;
  margin-left: 20px;
}

.ui.small.modal {
  margin-top: 5em !important;
}

.scenarioheader {
  text-align: center;
}

.walletdetails {
  width: 40%;
  margin: 0 auto;
  text-align: center;
  margin-top: 50px;
  min-width: 300px;
}

.indexlez {
  display: block;
  width: 40%;
  margin: 0 auto;
  min-width: 350px;
}

.lpcomp {
  display: block;
  width: 85%;
  margin: 0 auto;
  min-width: 350px;
  max-width: 750px;
}
.indexheaderdiv {
  text-align: center;
}
.indexheader {
  padding-top: 20px;
  padding-bottom: 15px;
  color: white;
}
.indexcontent {
  color: white;
}

.featureimg {
  width: 300px;
  max-width: 85%;
}

.tableheaderimg {
  float: left;
  display: block;
  width: 25%;
  max-width: 100px;
  padding-right: 10px;
}

.selected {
  border: 1px solid lightskyblue;
}

.edu {
  width: 95%;
  margin: 0 auto;
  text-align: left;
  color: white;
}

.infoheader {
  padding-bottom: 20px;
  padding-top: 40px;
  text-align: center;
  color: white;
}

.infoheadertop {
  text-align: center;
  padding-bottom: 30px;
}

.web {
  display: block;
  width: 40%;
  margin: 0 auto;
  min-width: 350px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.infoimage {
  height: 40px;
  display: inline-block;
}

.lezheader {
  display: block;
  padding-bottom: 30px;
  width: 25%;
  max-width: 500px;
  margin: 0 auto;
}

.factors {
  display: block;
  width: 40%;
  margin: 0 auto;
  min-width: 300px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.jump {
  position: fixed;
  bottom: 0;
  right: 0;
}

.tableHeader p {
  font-size: 0.75rem !important;
}

.modalbody {
  text-align: center;
}

.portcardheader {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 15%;
  min-width: 250px;
  margin-bottom: 0;
  padding-bottom: 30px;
  padding-top: 30px;
}

.unipanel {
  padding-top: 50px;
}

.sushipanel {
  padding-top: 50px;
  padding-bottom: 30px;
  text-align: center;
}

.portwalletdetails {
  position: fixed;
  top: 5px;
  right: 5px;
  width: 20%;
  min-width: 200px;
  text-align: center;
}

.smallerportwalletdetails {
  position: fixed;
  top: 5px;
  right: 5px;
  width: 10%;
  min-width: 100px;
  text-align: center;
}

.inlinetext {
  display: inline;
}
.walleticon {
  display: inline-block;
  margin-bottom: 20px;
}
@media (max-width: 925px) {
  .portwalletdetails {
    position: relative;
    width: 40%;
    margin: 0 auto;
    text-align: center;
    margin-top: 50px;
    min-width: 300px;
    margin-bottom: 50px;
  }

  .smallerportwalletdetails {
    position: relative;
    width: 20%;
    margin: 0 auto;
    text-align: center;
    margin-top: 50px;
    min-width: 150px;
    margin-bottom: 50px;
  }
}

@media (max-width: 400px) {
  .featurerow {
    padding-right: 30px;
  }
}

.portcardimg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 10%;
  min-width: 150px;
  margin-bottom: 0;
  padding-bottom: 30px;
}

.porttable {
  display: inline-block;
  color: white;
}

.featurerow {
  height: 300px;
  width: 100%;
  max-width: 1000px;
  min-width: 350px;
  margin: 0 auto;
  color: white;
}

.featureleft {
  float: left;
  width: 50%;
  height: 100%;
  line-height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.featureright {
  float: right;
  width: 50%;
  height: 100%;
  line-height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.featureleft2 {
  float: center;
  width: 50%;
  height: 100%;
  line-height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  color: white;
}

.featureright2 {
  float: center;
  width: 50%;
  height: 100%;
  line-height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  color: white;
}

.featurerow2 {
  min-height: 200px;
  width: 100%;
  min-width: 350px;
  margin: 0 auto;
  color: white;
  display: flex;
}

@media (max-width: 1000px) {
  .featureleft2 {
    float: center;
    width: 100%;
  }
  .featureright2 {
    float: center;
    width: 100%;
  }
  .featurerow2{
    display: block;
  }
}
.centerfeature {
  width: 100%;
}
.chartWrapper {
  width: 90%;
  padding-top: 20px;
  padding-bottom: 40px;
}
@media (max-width: 925px) {
  .ethtoggle {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 30px;
  }

  .porttable {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
}

.daterow{
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 30px;
}

.listelement {
  padding-bottom: 5px;
  color: white;
}

.nav-link {
  color: white !important;
}

.navbar-vertical {
  border-right: 1px solid gray;
}

.powerheader {
  color: white;
  // Add top padding to main content when navbar shift to top of screen (for small screens)
  @include media-breakpoint-down(sm) {
    padding-top: 100px;
  }
}

.shiftright {
  padding-right: 20px;
}

span.lpNum{
  background: #4883F6;
  border-radius: 1em;
  -moz-border-radius: 1em;
  -webkit-border-radius: 1em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 2em;
  margin-right: 5px;
  text-align: center;
  width: 2em; 
}

.react-daterange-picker__wrapper{
  color: white;
  border: thin solid white
}

.react-daterange-picker__clear{
  color: white
}

.react-daterange-picker__calendar{
color:white
}